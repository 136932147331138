import React, { useEffect, useCallback } from 'react'
import tw, { styled } from 'twin.macro'
import { useEmblaCarousel } from 'embla-carousel/react'

import { useRecursiveTimeout } from '../hooks/useRecursiveTimeout'
import { H3, Text } from '../components/core/typography'
import { GLink } from '../components/common'

import { getAnchor } from '../utils'

const CardLabel = tw.span`
  text-base
  font-semibold
  uppercase
`

const CardTitle = tw(H3)`
  my-0
  transition ease-in-out duration-150 delay-75
`

const CardText = tw(Text)`
  my-0
  transition ease-in-out duration-150
`

const CardContent = styled.div`
  ${({ small }) => !small && tw`md:(w-3/5)`}
`

const Card = styled(GLink)`
  ${tw`
    block
    overflow-hidden
    flex
    flex-col
    justify-between
    p-8
    h-full
    rounded-lg`}

  :hover {
    ${CardTitle} {
      ${tw`transform -translate-y-3 delay-0`}
    }

    ${CardText} {
      ${tw`transform -translate-y-3 delay-75`}
    }
  }

  ${({ light }) => (light ? tw`bg-ui-03` : tw`bg-decorative-03 text-text-04`)}
`

const ServiceCard = ({ label, title, description, small, ...props }) => (
  <Card {...props}>
    {!small && <CardLabel>{label}</CardLabel>}
    <CardContent small={small}>
      <CardTitle>{title}</CardTitle>
      <CardText>{description}</CardText>
    </CardContent>
  </Card>
)

const Carousel = tw.div`
  relative
`

const Viewport = tw.div`
  overflow-hidden
  w-full
`

const CarouselContainer = styled.div`
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  ${tw`h-72 -mt-4`}
`

const CarouselSlide = tw.div`
  relative
  h-full
  pt-4
`

const AUTOPLAY_INTERVAL = 5000

const ServiceCarousel = ({ services }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    axis: 'y',
    draggable: false,
    loop: true
  })

  const autoplay = useCallback(() => {
    if (!embla) return
    if (embla.canScrollNext()) {
      embla.scrollNext()
    } else {
      embla.scrollTo(0)
    }
  }, [embla])

  const { play } = useRecursiveTimeout(autoplay, AUTOPLAY_INTERVAL)

  useEffect(() => {
    play()
  }, [play])

  // Test multiple slides
  // const SLIDE_COUNT = 3
  // const slides = Array.from(Array(SLIDE_COUNT).keys())
  // services = slides.map(() => services[0])

  return (
    <div tw="grid grid-cols-2 gap-4">
      <Carousel tw="col-span-2 rounded-lg">
        <Viewport ref={viewportRef}>
          <CarouselContainer>
            {services &&
              services.map((service, index) => (
                <CarouselSlide key={index}>
                  <ServiceCard
                    to={`/diensten#${getAnchor(service.slug)}`}
                    label={service.frontmatter.label}
                    title={service.frontmatter.title}
                    description={service.frontmatter.description}
                    light={index % 3 === 2}
                  />
                </CarouselSlide>
              ))}
          </CarouselContainer>
        </Viewport>
      </Carousel>
    </div>
  )
}

export default ServiceCarousel
