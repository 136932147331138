import React, { useState, useEffect, useCallback } from 'react'
import tw, { styled } from 'twin.macro'
import { useEmblaCarousel } from 'embla-carousel/react'

const Carousel = tw.div`
  relative
`

const Viewport = tw.div`
  overflow-hidden
  w-full
  h-full
`

const Container = styled.div`
  display: flex;
  height: 100%;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
`

const CarouselSlide = tw.div`
  relative
  min-w-full
`

const NavDotsContainer = tw.div`
  absolute
  bottom-0
  min-w-full
`

const NavDots = tw.div`
  flex
  justify-center
  mb-2
`

const DotButton = styled.button(({ selected }) => [
  tw`
    flex
    justify-center
    items-center
    w-5
    h-5
    mx-2
    bg-transparent
    after:(content w-3 h-3 bg-transparent border-ui-02 border-2 rounded-full)
    hocus:(outline-none)
  `,
  selected && tw`after:(bg-ui-02)`
])

const CarouselContent = ({ children }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    loop: true,
    draggable: false
  })
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])

  const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [
    embla
  ])

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
  }, [embla, setSelectedIndex])

  useEffect(() => {
    if (!embla) return
    onSelect()
    setScrollSnaps(embla.scrollSnapList())
    embla.on('select', onSelect)
  }, [embla, setScrollSnaps, onSelect])

  return (
    <>
      <Viewport ref={viewportRef}>
        <Container>{children}</Container>
      </Viewport>
      <NavDotsContainer>
        <NavDots>
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </NavDots>
      </NavDotsContainer>
    </>
  )
}

export { Carousel, CarouselContent, CarouselSlide }
