import React from 'react'
import tw from 'twin.macro'
import { useStaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image/withIEPolyfill'

const Cloud = tw.div`
  grid
  grid-cols-6
  gap-4
`
const CloudItem = tw.div`
  col-span-3
  md:(col-span-2)
  flex
  justify-center
  items-center
  px-6
  py-6
  sm:(px-10)
  bg-ui-03
  rounded
`

const StyledImg = tw(Img)`
  opacity-75
`

const PartnerCloud = () => {
  const data = useStaticQuery(
    graphql`
      query Partners {
        allPartner {
          edges {
            node {
              name
              site
              logo {
                childImageSharp {
                  fluid(maxHeight: 80, grayscale: true) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const { edges: partners } = data.allPartner
  return (
    <Cloud>
      {partners &&
        partners.map(({ node: partner }) => (
          <CloudItem>
            <a tw="flex-grow" href={partner.site}>
              <StyledImg
                fluid={partner.logo.childImageSharp.fluid}
                objectFit="contain"
                alt={partner.name}
              ></StyledImg>
            </a>
          </CloudItem>
        ))}
    </Cloud>
  )
}

export default PartnerCloud
