import React from 'react'
import tw, { css, styled } from 'twin.macro'
import { useEmblaCarousel } from 'embla-carousel/react'
import { Link } from 'gatsby'

import EventCard from './EventCard'

const viewportDraggableStyles = css`
  ${tw`cursor-move cursor-grab`}
`

const viewportDraggingStyles = css`
  ${tw`cursor-grabbing`}
`

const Viewport = styled.div`
  ${tw`
    overflow-hidden
    w-full`}
  &.is-draggable {
    ${viewportDraggableStyles}
  }
  &.is-dragging {
    ${viewportDraggingStyles}
  }
`

const CarouselContainer = styled.div`
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  ${tw`-ml-8`}
`

const CarouselSlide = tw.div`
  relative
  min-w-full
  sm:min-w-1/2
  lg:min-w-2/6
  pl-8
`

const SlideInner = styled.div`
  ${tw`
    relative
    overflow-hidden`}
`

const StyledCarousel = tw.div`
  relative
`

const EventCarousel = ({ events }) => {
  const [viewportRef] = useEmblaCarousel({
    align: 'center',
    containScroll: 'trimSnaps'
  })

  // Test multiple slides
  // const SLIDE_COUNT = 8
  // const slides = Array.from(Array(SLIDE_COUNT).keys())
  // events = slides.map(() => events[0])

  return (
    <StyledCarousel>
      <Viewport ref={viewportRef}>
        <CarouselContainer>
          {events.map((event) => (
            <CarouselSlide>
              <SlideInner>
                <Link to={event.slug}>
                  <EventCard
                    image={event.frontmatter.coverImage}
                    title={event.frontmatter.title}
                    startDate={event.frontmatter.startDate}
                  />
                </Link>
              </SlideInner>
            </CarouselSlide>
          ))}
        </CarouselContainer>
      </Viewport>
    </StyledCarousel>
  )
}

export default EventCarousel
