import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import {
  Carousel,
  CarouselContent,
  CarouselSlide
} from '../components/Carousel'
import { GLink } from '../components/common'

import { Container } from '../components/core/container'
import { H2, Text } from '../components/core/typography'
import PartnerCloud from '../components/PartnerCloud'
import EventCarousel from '../components/EventCarousel'
import ServiceCarousel from '../components/ServiceCarousel'

const ContentWrapper = tw(Container)`
  my-24
  max-w-screen-lg
`

const ContentBackground = styled.div`
  ${tw`
    relative
    before:(content absolute inset-0 -z-10)`}
`

const StyledH2 = tw(H2)`
  text-4xl
  sm:(text-5xl)
`

const StyledText = tw(Text)`
  mt-0
  text-text-03
  text-lg
  sm:(text-xl)
`

const Accent = tw.span`
  text-decorative-03
`

const Button = tw.button`
  bg-interactive-01
  py-3
  px-5
  rounded
  text-text-04
  font-medium
  hocus:(bg-hover-primary)
  hocus:(outline-none)
`
export const IndexPageTemplate = ({
  heading,
  subheading,
  featuredServices,
  featuredEvents
}) => (
  <>
    {/* Hero */}
    <div tw="relative flex justify-end bg-ui-05 mx-auto overflow-hidden">
      <div tw="flex flex-col md:flex-row  max-w-screen-xl">
        <div tw="relative flex items-center md:w-1/2">
          <svg
            tw="hidden z-10 md:block absolute right-0 inset-y-0 h-full w-48 text-ui-05 transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>
          <div tw="px-10 py-10 max-w-sm">
            <h1 tw="text-decorative-02 text-base">{heading.toUpperCase()}</h1>
            <h2 tw="mt-8 text-4xl text-text-04 leading-tight">{subheading}</h2>
          </div>
        </div>
        <Carousel tw="md:w-1/2">
          <CarouselContent>
            <CarouselSlide>
              <img
                tw="h-64 md:h-full w-full object-cover object-top"
                src="carousel-01.jpg"
                alt=""
              ></img>
            </CarouselSlide>
            <CarouselSlide>
              <img
                tw="h-64 md:h-full w-full object-cover object-top"
                src="carousel-02.jpg"
                alt=""
              ></img>
            </CarouselSlide>
            <CarouselSlide>
              <img
                tw="h-64 md:h-full w-full object-cover object-top"
                src="carousel-03.jpg"
                alt=""
              ></img>
            </CarouselSlide>
          </CarouselContent>
        </Carousel>
      </div>
    </div>

    {/* Event section */}
    <section>
      <ContentWrapper>
        <StyledH2>
          evenementen<Accent>_</Accent>
        </StyledH2>
        <StyledText>
          Neem deel als bedrijf of student aan één van onze evenement en ondenk
          wat je elkaar te bieden hebt
        </StyledText>
      </ContentWrapper>
      {featuredEvents.length !== 0 ? (
        <Container>
          <EventCarousel events={featuredEvents} />
        </Container>
      ) : (
        <StyledText>Geen evenementen om weer te geven</StyledText>
      )}

      {/* Service section */}

      <ContentWrapper>
        <StyledH2>
          diensten<Accent>_</Accent>
        </StyledH2>
        <StyledText>Ontdek wat we te bieden hebben</StyledText>
      </ContentWrapper>
    </section>

    <ContentBackground tw="before:(bg-ui-01 top-16 -bottom-40)">
      <section>
        <ContentWrapper tw="mt-0">
          <ServiceCarousel services={featuredServices} />
        </ContentWrapper>
      </section>

      {/* Sponsor section */}

      <ContentWrapper>
        <StyledH2>
          sponsors <Accent>&</Accent> partners
        </StyledH2>
        <StyledText>Met trots ondersteund door...</StyledText>
      </ContentWrapper>
    </ContentBackground>

    <ContentBackground tw="before:(bg-ui-05 top-16 -bottom-24)">
      <ContentWrapper>
        <PartnerCloud />
      </ContentWrapper>

      {/* Info section */}

      <ContentWrapper tw="flex flex-col md:(space-x-24 flex-row items-end)">
        <div tw="text-text-04">
          <StyledH2>
            meer weten<Accent> ?</Accent>
          </StyledH2>
          <StyledText>
            Bekijk ons volledige aanbod of neem contact op
          </StyledText>
        </div>
        <div tw="flex space-x-8 mb-4">
          <Button as={GLink} to="/diensten">
            Aanbod
          </Button>
          <Button as="a" href="mailto:pbr@boerekot.be">
            Contact
          </Button>
        </div>
      </ContentWrapper>
    </ContentBackground>
  </>
)

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const featuredServices = data.featuredServices.edges.map((edge) => ({
    frontmatter: edge.node.frontmatter,
    slug: edge.node.fields.slug
  }))
  const featuredEvents = data.featuredEvents.edges.map((edge) => ({
    frontmatter: edge.node.frontmatter,
    slug: edge.node.fields.slug
  }))

  return (
    <Layout>
      <IndexPageTemplate
        featuredServices={featuredServices}
        featuredEvents={featuredEvents}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
      />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
      }
    }

    featuredServices: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "service" }, featured: { eq: true } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            description
            featured
            label
            title
          }
        }
      }
    }

    featuredEvents: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "event-page" }
          featured: { eq: true }
        }
      }
      limit: 5
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            coverImage {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            startDate
          }
        }
      }
    }
  }
`
